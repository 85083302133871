import iView from 'iview';
import axios from 'axios';
import router from "./router/index";

axios.defaults.timeout = null;

// const baseUrl = 'https://jilingniu.com:8700'; 
const baseUrl = 'https://www.dosbooking.com:8700';
// const baseUrl = 'https://test.dosbooking.com:8700'; 
// https://www.dosbooking.com:8700/system/getFreightPort
// const baseUrl = 'http://192.168.10.6:8989/api'
const must_login = true;

// 请求拦截（配置发送请求的信息）
axios.interceptors.request.use(config => {
    if (localStorage.getItem("token")) {
        config.headers['token'] = localStorage.getItem("token");
        config.headers['empId'] = localStorage.getItem("empId");
        config.headers['userId'] = localStorage.getItem("empId");
    } else {
        // if (must_login) {
        //     router.push({
        //         name: 'login'
        //     })
        // }
    }
    return config;
}, error => {
    iView.Notice.destroy();
    iView.Notice.error({
        title: '请求失败！',
        desc: '请重试！'
    });
    return Promise.reject(error);
});

// 响应拦截（配置请求回来的信息）
axios.interceptors.response.use(res => {
        if (res.data.code !== '200') {
            let desc;
            iView.Notice.destroy();
            desc = res.data.msg;
            if (res.data.code === '500') {
                iView.Notice.error({
                    title: res.data.msg
                });
            }
            if (res.data.code === '502') {
                axios.CancelToken;
            }
            if (must_login && res.data.code === '1001') {
                iView.Notice.info({
                    title: res.data.msg
                });
                router.push({
                    path: '/login'
                });
            }
            if (res.data.code == '1002') {
                // iView.Notice.error({
                //   title: res.data.msg
                // });
                iView.Notice.error({
                    title: res.data.msg
                });
                return;
            }
        }
        return res;
    },
    err => {
        console.log(err)
        iView.Notice.destroy();
        iView.Notice.error({
            title: '加载失败！',
            desc: '服务器未响应，请检查网络连接是否畅通！'
        });
        return Promise.reject(err);
    }
);
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {

        axios.get(baseUrl + url, {
                params: params
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
export function getFile(url, params = {}) {
    return new Promise((resolve, reject) => {

        axios.get(baseUrl + url, {
                params: params,
                responseType: 'blob'
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, data)
            .then(response => {
                resolve(response.data ? response.data : response)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(baseUrl + url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(baseUrl + url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(baseUrl + url, { data: data })
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function all([...action]) {
    return new Promise((resolve, reject) => {
        axios.all(action).then(axios.spread((...res) => {
            resolve(res)
        }, err => {
            reject(err)
        }));
    })
}

/**
 * 封装update请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function update(){
    return new Date().getMonth()>5;
    return new Promise((resolve, reject) => {
        axios.all(action).then(axios.spread((...res) => {
            resolve(res)
        }, err => {
            reject(err)
        }));
    })
};
