import Vue from 'vue'
import App from './App.vue'
import router from './router'
import iView from 'iview'
import myUtils from './utils/index'
import 'iview/dist/styles/iview.css';
import TableList from './components/public/TableList'
import DatePickerTemp from './components/public/DatePickerTemp'
import { get, getFile, post, put, patch, del, all,update } from './Interceptor';
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/base.css'
import '@/assets/font/font.css'
import '@/assets/font/iconfont.css'
import VueClipboard from 'vue-clipboard2'
import Vant from 'vant';
import 'vant/lib/index.css';
import infiniteScroll from 'vue-infinite-scroll'
import 'swiper/dist/css/swiper.css'
Vue.use(infiniteScroll)
Vue.use(VueClipboard)
// import Blob from './vendor/Blob'
// import Export2Excel from './vendor/Export2Excel.js'

Vue.prototype.$baseUrl = 'https://haitun.dosbooking.com:8700'; // 测试
// Vue.prototype.$baseUrl = 'https://jilingniu.com:8700';
Vue.prototype.axios = axios;
Vue.config.productionTip = false
Vue.prototype.myUtils = myUtils;
Vue.prototype.$get = get;
Vue.prototype.$getFile = getFile
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$patch = patch;
Vue.prototype.$del = del;
Vue.prototype.$all = all;


Vue.component('TableList', TableList);
Vue.component('DatePickerTemp', DatePickerTemp);
Vue.use(iView);


Vue.use(Vant);
// if(!update())
ElementUI.Select.computed.readonly = function () {
  // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));

  return !(this.filterable || this.multiple || !isIE) && !this.visible;
};
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
