<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="543px"
      :before-close="handleClose"
    >
      <div class="error">
        <!-- <i class="el-icon-info"></i> -->
        <div style="font-size: 18px">提交卖舱后客服会与您联系</div>
        <div class="errbutton" style="margin: 30px 0">
          <el-button
            @click="handleClose"
            style="
              width: 82px;
              height: 33px;
              font-size: 14px;
              line-height: 9px;
              font-size: 14px;
              border: 1px solid #00bafd;
              color: #00bafd;
            "
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="tianxie"
            style="
              font-size: 14px;
              width: 109px;
              line-height: 9px;
              height: 33px;
              background-color: #00bafd;
              border-color: #00bafd;
              font-size: 14px;
              color: #fff;
            "
            >去填写</el-button
          >
        </div>
      </div>
    </el-dialog>
    <div
      @click="stylePortClick"
      v-show="dialogVisibleInfo"
      class="dialog"
      :class="!stylePort ? 'dislog' : ''"
    >
      <div class="dialogMain">
        <div style="padding: 0 20px 20px 20px" class="dialogHeader">
          <span class="el-dialog__title">我要卖舱</span
          ><i
            @click="handleClose"
            class="el-icon-close"
            style="font-size: 20px"
          ></i>
        </div>
         <!-- <div style="text-align:center;color:#ACACAC" >
               提交寻舱后,海豚寻舱会自动帮助您寻找价格最低的舱位
          </div> -->
       <div style="">
          <el-form label-width="130px" style="padding: 20px">
          <el-form-item label="起始港：" required>
            <div class="post">
              <el-select
                v-model="startSeaport"
                filterable
                clearable
                :filter-method="getFreightPort1"
                placeholder="请选择起始港"
                style="width: 302px"
              >
                <el-option
                  v-for="item in FreightPort1"
                  :key="item.id"
                  :label="item.cname"
                  :value="item.cname"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.cname
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="目的港：" required>
            <div class="post">
              <el-select
                @visible-change="visibleChange"
                ref="startSeaportVisible"
                @change="blurChange"
                v-model="targetSeaport"
                filterable
                clearable
                :filter-method="getFreightPort"
                placeholder="请输入目的港"
                style="width: 302px"
              >
                <el-option
                  v-for="item in FreightPortList1"
                  :key="item.id"
                  :label="item.cname"
                  :value="item.cname"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.cname
                  }}</span>
                </el-option>
              </el-select>
              <div
                class="stylePort"
                :tabindex="1"
                v-show="stylePort"
                @blur="stylePortBlur"
              >
                <div class="pordtTitle">
                  <div
                    v-for="(item, index) in portJson"
                    :key="index"
                    class="jsonTitle"
                    :class="JsonTitleIndex == index ? 'titleborder1' : ''"
                    @click.stop="JsonTitleIndex = index"
                  >
                    {{ item.title }}
                  </div>
                </div>

                <div class="portJsonSmallbox">
                  <div
                    v-for="(prot, index) in portJson[JsonTitleIndex].json"
                    :key="index"
                  >
                    <div class="portJsonSmall">
                      {{ prot.smallTitle }}
                    </div>
                    <ul class="smallJson clearfix">
                      <li
                        v-for="(small, smallindex) in prot.json"
                        :key="smallindex"
                        @click="selectSmall(small.cname)"
                      >
                        <span href="" :title="small.name + small.cname">
                          <span
                            :class="
                              small.name == params.targetSeaport
                                ? 'selectSmall'
                                : ''
                            "
                          >
                            {{ small.name }}({{ small.cname }})
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="船司： " label-width="117px">
            <el-select
              v-model="shipCompanyName"
              filterable
              clearable
              :filter-method="getFreightCompany"
              placeholder="请选择船司"
              style="width: 302px;margin-left:13px"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.cname
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格预算(USD)： " label-width="180px">
            <el-input
              :controls="false"
              v-model="cabinetPredict"
              style="width: 250px"
              placeholder="请输入价格预算"
            ></el-input>
          </el-form-item>
          <el-form-item label="截单时间：" label-width="140px">
            <el-date-picker
              style="width: 290px"
              v-model="closeOrderTime"
              type="date"
              align="right"
              :picker-options="pickerOptions"
              placeholder="请选择截单时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
       </div>
   
        <div slot="footer" class="dialog-footer" style="display: flex;justify-content: end;">
          <el-button
            type="primary"
            @click="conformSeek"
            :loading="confirmLoading"
            style="
              width: 82px;
              height: 33px;
              background-color: #00bafd;
              border: 1px solid #00bafd;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            >提交</el-button
          >
          <el-button
            @click="handleClose"
            style="
              width: 82px;
              height: 33px;
              border: 1px solid #00bafd;
              color: #00bafd;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            >取 消</el-button
          >
        </div>
      </div>
      <!-- </el-dialog>  -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        suppCompany: "",
        suppLinkman: "",
        suppPhone: "",
      },
      portJson: [
        {
          json: [],
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //禁止选择今天以前的时间
        },
      },
      FreightPortList1: [],
      JsonTitleIndex: 0,
      stylePort: false,
      dialogVisible: false,
      dialogVisibleInfo: false,
      closeOrderTime: "",
      cabinetPredict: "",
      targetSeaport: "",
      dislog: false,
      companyList: [],
      shipCompanyName: "",
      startSeaport: "",
      FreightPort1: [],
      confirmLoading: false,
    };
  },
  created() {},
  methods: {
    errshowoLoad() {
      this.dialogVisible = true;
      this.$emit("zindeshow");
      // debugger
      this.getFreightPortJson();
      this.getFreightPort();
      this.getFreightCompany();
      this.getFreightPort1();
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.$get("/supp/getHistoryByUserId", { userId: userInfo.userId }).then(
        (res) => {
          if (res.data) {
            this.params = res.data;
          }
        }
      );
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleInfo = false;
      this.$emit("zindehide");
    },
    handleCloseInfo() {
      this.dialogVisibleInfo = false;
      this.$emit("zindehide");
    },
    tianxie() {
      this.dialogVisible = false;
      this.dialogVisibleInfo = true;
    },
    confirmBut() {
      if (this.params.suppCompany == "") {
        this.$message.error("请输入公司名称");
        return false;
      }
      if (this.params.suppLinkman == "") {
        this.$message.error("请输入联系人姓名");
        return false;
      }
      if (this.params.suppPhone == "") {
        this.$message.error("请输入联系方式");
        return false;
      }
      // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let params = {
        suppCompany: this.params.suppCompany,
        suppLinkman: this.params.suppLinkman,
        suppPhone: this.params.suppPhone,
        // userId:userInfo.userId
      };
      this.$post("/workOrder/saleOrder", params).then((res) => {
        if (res.code == "200") {
          this.$message.success("提交成功，请耐心等待工作人员与您联系");
          this.$router.push("/user?type=4");
          this.dialogVisibleInfo = false;
        }
      });
    },
    getFreightPortJson() {
      this.$post("/system/getFreightPortJson1").then((res) => {
        this.portJson = res.data.list;
        this.JsonTitle = res.data.list[0].title;
      });
    },
    getFreightCompany(val) {
      this.$post("/system/getFreightCompany", { name: val }).then((res) => {
        this.companyList = res.data.list;
      });
    },
    visibleChange(bool) {
      // debugger

      // debugger
      // this.stylePort = bool
      //  if(this.stylePort){
      //          this.dislog = true
      //         this.stylePort = true
      //     }
      if (bool) {
        this.dislog = true;
        this.stylePort = true;
      }
      console.log(111, bool, this.stylePort);
    },
    getFreightPort(val) {
      this.stylePort = false;
      this.params.targetSeaport = val;
      this.$post("/system/getFreightPort", { type: "target", name: val }).then(
        (res) => {
          this.FreightPortList1 = res.data.list;
        }
      );
    },
    getFreightPort1(val) {
      //   this.params.targetSeaport = val
      this.$post("/system/getFreightPort", { name: val }).then((res) => {
        this.FreightPort1 = res.data.list;
      });
    },
    stylePortClick() {
      // alert('11')
      this.stylePort = false;
    },
    stylePortBlur() {
      this.stylePort = false;
    },
    stylePortClick() {
      // alert('11')
      this.stylePort = false;
      // this.dialogVisible = false
      this.startStylePort = false;
    },
    stylePortBlur() {
      // this.stylePort = false
    },
    blurChange() {
      console.log(111);
      this.stylePort = false;
      this.startStylePort = false;
    },
    selectSmall(name) {
      this.targetSeaport = name;
      this.stylePort = false;
    },
    conformSeek() {
      if (this.startSeaport == "") {
        this.$message.error("请输入目的港");
        return false;
      }
      if (this.targetSeaport == "") {
        this.$message.error("请输入起始港");
        return false;
      }
      let params = {
        startSeaport: this.startSeaport,
        targetSeaport: this.targetSeaport,
        shipCompanyName: this.shipCompanyName,
        cabinetPredict: this.cabinetPredict,
        closeOrderTime: this.closeOrderTime,
      };
      this.confirmLoading = true;
      this.$post("/workOrder/genOrder", params).then((res) => {
        this.confirmLoading = false;
        if (res.code == "200") {
          this.$message.success("提交成功，请耐心等待工作人员与您联系");
          this.dialogVisibleInfo = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
  //   height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}
::v-deep .el-dialog {
  border-radius: 12px;
}
.error i {
  color: #e6a23c;
  font-size: 28px;
}
.errbutton {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
}
.box {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-items: center;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center i {
  color: #ff0000;
}
.inputBox {
  width: 160px;
}
.stylePort {
  position: absolute;
  z-index: 99999 !important;
  width: 600px;
  display: block;
  height: 360px;
  box-shadow: 0px 1px 3px #666;
  background: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.jsonTitle{
    display: inline-block;
//    height: 26px;
   text-align: center;
   line-height: 26px;
//    padding: 0 12px;
   margin-right: 15px;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
}
.stylePort{
    position: absolute;
    z-index: 99999 !important;
    width: 540px;
    display: block;
    height: 360px;
     box-shadow: 0px 0px 40px 1px rgba(0, 186, 253, 0.2);
    // box-shadow:0 0 20px 0px #00bafd, inset 0 0 0 0px #00bafd;
    background: #fff;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.pordtTitle{
    margin-top: 8px;
    // width: 100%;
    padding:0 10px;
    // position: fixed;
    z-index: 999;
    background: #fff;
    font-size: 14px;
    //   border-bottom:1px solid rgba(22, 119, 255, 1);
}
.portJsonSmallbox {
  height: 300px;
  overflow-y: scroll;
}
.titleborder {
  // border-bottom: none !important;
  // height: 48px;
}
.titleborder1{
   background: #00BAFD;
   color:#fff;
   padding: 3px 8px;
   text-align: center;
   border-radius: 35%;
}


.portJsonSmall{
    // border-left: 3px solid rgba(22, 119, 255, 1);
    height: 36px;
    // background: #F9F9F9;
    line-height: 36px;
    text-align: left;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 500;
    margin: 15px 15px 0 15px;
    color:rgba(196, 196, 196, 1)
    // padding: 0 8px;
}
.smallJson{
   margin: 10px;
}
.smallJson li{
    float: left;
}
.smallJson li :hover{
    // background: rgba(22, 119, 255, 0.1);
    color:rgba(0, 186, 253, 1)
}
.smallJson li span{
 display: block;
    line-height: 24px;
    width: 172px;
    padding: 4px 6px;
    text-align: left;
    font-size: 12px;
    color: #325d97;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.selectSmall{
    background: #1677FF;
    color: #fff !important;
} 
.selectSmall {
  background: #1677ff;
  color: #fff !important;
}
.post {
  position: relative;
  z-index: 99999;
}
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
}
.dialogMain {
  padding: 30px;
  width: 500px;
  padding-left: 0;
  position: relative;
  z-index: 999;
  background: #fff;
  border-radius: 12px;
  //  height: 524px;
  //  overflow: hidden;
  ::v-deep .el-select .el-input__inner {
    height: 35px;
  }
  ::v-deep .el-input__inner {
    height: 35px;
  }
}
.dialog-footer {
  text-align: right;
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dislog {
  z-index: 0 !important;
 
}
::v-deep .el-dialog__header {
  padding: 41px 58px 10px !important;
}
::v-deep .el-dialog__headerbtn {
  top: 45px !important;
}
.seekDiv{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.seekDiv > div{
    line-height: 20px;
    margin-bottom: 10px;
}

</style>