<template>
  <DatePicker
      :type="(type.toLowerCase()==='datetime'?'datetime':'date')"
      :format="type.toLowerCase()==='datetime'?'yyyy-MM-dd HH:mm:ss':'yyyy-MM-dd'"
      :value="value"
      :editable="editable"
      @on-change="dateChange"
      :clearable="clearable"
      :placement="placement"
      style="width: 100%"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
      :size="'small'"
      transfer></DatePicker>
</template>
<script>
  /**
   *
   * 基于 iview 的支持双向绑定的时间选择器
   */

  export default {
    data() {
      return {}
    },
    props: {
      type: {
        type: String,
        default: 'date',
      },
      value: {
        type: [String, Date],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      placement: {
        type: String,
        default: 'bottom-start'
      },
      options: {
        type: Object
      },
      clearable: {
        type: Boolean,
        default: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      value: function (data) {
        this.dateChange(data ? this.myUtils.formatDate(data, this.type === 'datetime' ? 'yyyy-MM-dd hh:mm:ss' : 'yyyy-MM-dd') : '');
      }
    },
    mounted() {
      this.dateChange(this.value ? this.myUtils.formatDate(this.value, this.type === 'datetime' ? 'yyyy-MM-dd hh:mm:ss' : 'yyyy-MM-dd') : '');
    },
    methods: {
      dateChange(date) {
        this.$emit('input', date);
        this.$emit('on-change', date);
      },
    }
  }
</script>
