<template>
  <div class="header">
      <div class="headerbox">
          <div class="headerTitle">
             <div class="flex">
                <div class="titleBorder">
                    运费询价热线：0755-25100671
                </div>
                <el-tooltip
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                        <img src="../../assets/images/xiaochengxu.jpg" alt="" srcset="" style="width:200px;height:200px">
                    </div>
                    <div class="cursor" >
                        海豚寻舱官方小程序
                    </div>
                </el-tooltip>
                <div class="titleBorder"></div>
                <el-tooltip
                    placement="top"
                    effect="light"
                  >
                    <div slot="content">
                        <img src="./wechart.jpg" alt="" srcset="" style="width:200px;height:200px">
                    </div>
                    <div class="cursor" >
                        官方微信
                    </div>
                </el-tooltip>
             </div>
            <div class="flex " style="align-items: center;">
                <div>
                    <div class="flex">
                    <div  class="cursor" @click="maichang">
                        我要卖舱
                    </div>
                  
                    <div style="margin:0 5px">
                        /
                    </div>
                     <div style="margin-right:20px" class="cursor" @click="seekPush" :class="routername == 'seek' ? 'colorCalss' : ''">
                        我要寻舱
                    </div>
                </div>
                </div>
                 <div class="headerLogin" v-if="token == ''">
                        <div @click="login">
                            登录
                        </div>
                        <div style="margin-left:20px" @click="register">
                            注册
                        </div>
                    </div>
                    <div class="userInfo" v-if="token != ''" @click="user">
                        <div class="touxiang">
                            {{userNick}}
                        </div>
                        <div class="phone">
                            {{phone}}
                        </div>
                    </div>
            </div>
          </div>
        
        <!-- <div class="discenter">
            <div class="cursor" @click="goIndex">
                <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" srcset="" style="height:77px;width:257px">
            </div>
            <div class="flex shouye" >
                
                <div class="cursor" :class="routername == 'index' ? 'backgroundClass' : ''" @click="goIndex">
                    首页  
                </div>
                <div style="margin-left: 20px;" class="cursor" :class="routername == 'order' ? 'backgroundClass' : ''"  @click="order">
                    订单中心
                </div>
                <div style="margin-left: 20px;" class="cursor" :class="routername == 'user' ? 'backgroundClass' : ''"  @click="user">
                    个人中心
                </div>
            </div>
        </div>
       <div style="text-align:right" class="discenter">
           <div class="flex">
               <div class="cursor" @click="seekPush" :class="routername == 'seek' ? 'colorCalss' : ''">
                   我要寻舱
               </div>
               <div style="margin:0 5px">
                   /
               </div>
               <div style="margin-right:20px" class="cursor" @click="maichang">
                   我要卖舱
               </div>
           </div>
            <div class="cursor login" v-show="token == ''" @click="login">
            登录/注册
            </div>
        <el-popover
            placement="bottom"
            title=""
           
            v-show="token != ''"
            width="200"
            trigger="hover"
            content="">
            
            <el-avatar slot="reference" size="large" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                <div class="infoBox">
                <div class="infoMain">
                    <div>
                        <el-avatar slot="reference" size="medium" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                    </div>
                    <div>
                        <div style="margin-left:12px;font-size:12px">
                            {{phone}}
                        </div>
                    </div>
                </div>
                <div class="flex box1">
                    
                    <div class="box cursor" @click="user">
                        <div>
                                <i class="el-icon-user"></i>
                        
                        </div>
                        个人中心
                    </div>
                    <div class="box cursor" style="margin-left:20px" @click="order">
                        <div>
                                <i class="el-icon-tickets"></i>
                        
                        </div>
                        订单中心
                    </div>
                </div>
                <div class="loginOut cursor" @click="loginOut">
                    退出登录
                    </div>
            </div>
        </el-popover>
         -->
       <!-- </div> -->
      </div>
      <div class="daohang">
       <div class="daohangbox">
            <div style="margin-top:14px;margin-bottom:12px" class="flex" v-if="routername == 'index'">
                    <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" srcset="" style="height:77px;width:257px" @click="goIndex" class="cursor">
                     <!-- <img src="./logo.png" alt="" srcset="" style="height:50px;width:100px" @click="goIndex" class="cursor"> -->
                     <div>
                    <div class="flex daohanglan">
                        <div :class="routername == 'index' ? 'backgroundClass' : ''" @click="goIndex">
                            寻舱首页
                        </div>
                        <div :class="routername == 'search' ? 'backgroundClass' : ''" @click="goSearch">
                            航线列表
                        </div>
                        <div :class="routername == 'order' ? 'backgroundClass' : ''" @click="order">
                            订单中心
                        </div>
                        <div :class="routername == 'user' ? 'backgroundClass' : ''" @click="user">
                            个人中心
                        </div>
                    </div>
                </div>
            </div> 
            <div style="margin-top:14px;margin-bottom:12px" class="flex" v-if="routername != 'index'">
                    <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" srcset="" style="height:50px;width:167px" @click="goIndex" class="cursor">
                     <!-- <img src="./logo.png" alt="" srcset="" style="height:50px;width:87px" @click="goIndex" class="cursor"> -->
                     <div>
                    <div class="flex daohanglan" style="margin-top:20px;margin-left:10px">
                        <div :class="routername == 'index' ? 'backgroundClass' : ''" @click="goIndex">
                            寻舱首页
                        </div>
                        <div :class="routername == 'search' ? 'backgroundClass' : ''" @click="goSearch">
                            航线列表
                        </div>
                        <div :class="routername == 'order' ? 'backgroundClass' : ''" @click="order">
                            订单中心
                        </div>
                        <div :class="routername == 'user' ? 'backgroundClass' : ''" @click="user">
                            个人中心
                        </div>
                    </div>
                </div>
            </div> 
       </div>
    </div>
    <!-- <sellingPort ref="sellingPort" @zindeshow="zindeshow" @zindehide="zindehide"></sellingPort>   
    <seekPort ref="seekPort" @zindeshow="zindeshow" @zindehide="zindehide"></seekPort> -->
    <login ref="login" @info="getInfo"></login>
    <notify ref="notify"></notify>
  </div>
</template>

<script>
import sellingPort from './sellingPort.vue'
import seekPort from './seekPort.vue'
import login from './login.vue'
import notify from './notify.vue'
export default {
    components:{sellingPort,seekPort,login,notify},
    data() {
        return {
            token:'',
            visible:true,
            phone:'',
            route:this.$route,
            routername:''
        }
    },
   watch:{
        $route(to,from){
            if(to.query.type == '1'){
                this.routername = 'user'
            }
            if(to.query.type == '2'){
                this.routername = 'order'
            }
            console.log('to',to);
        }
    },
    mounted(){
      
         if(this.$route.path == '/index'){
            this.routername = 'index'
        }
        if(this.$route.path == '/user'){
            if(this.$route.query.type == '1'){
                this.routername = 'user'
            }
            if(this.$route.query.type == '2'){
                this.routername = 'order'
            }
        }
        if(this.$route.path == '/seek'){
            this.routername = 'seek'
        }
        if(this.$route.path == '/search'){
            this.routername = 'search'
        }
        // debugger
        let token = localStorage.getItem('token')
		if(!token || token == 'null' || token == '' || token == 'undefined'){
			// next('/login')

		}else{
			this.token = token
            let userInfo = localStorage.getItem('userInfo')
            this.phone = JSON.parse(userInfo).user.phone
            this.getUserInfo(userInfo.userId)
            this.userNick = JSON.parse(userInfo).user.userNick.charAt(0)
            // this.
		}
       
         console.log(111,this.$route.query.type )
        // if(this.$route.query){
        //     this.
        //     console.log(this.$route)
        // }
       
    },
    methods:{
        
        getInfo(){
            // debugger
            let token = localStorage.getItem('token')
            if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')

            }else{
                this.token = token
                let userInfo = localStorage.getItem('userInfo')
                this.phone = JSON.parse(userInfo).user.phone
                this.getUserInfo(userInfo.userId)
                  let userInfouser= JSON.parse(localStorage.getItem('userInfo')).user;
                        // item.user.bizStatus || item.user.bizStatus == 'waitCheck'
                        // !item.user.bizStatus || item.user.bizStatus == 'waitCheck'
                        if( userInfouser.bizStatus =='waitCheck'){
                            console.log(userInfouser)
                            this.$refs.notify.showNotify()
                        }
                this.userNick = JSON.parse(userInfo).user.userNick.charAt(0)
                // this.
                

            } 
        },
        
        zindehide(){
            this.$emit("zindehide")
            // debugger
            this.zIndex = false
        },
        zindeshow(){
            // debugger
            this.$emit("zindeshow")
            this.zIndex = true
        },
        maichang(){
            // debugger
            let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.$router.push('/login')
            } else {
                this.$emit('fabucangwei')
                // this.$refs.sellingPort.errshowoLoad()
                //   this.$emit('updataSelect')
                // this.$router.push('/user?type=2')
            }
        },
        goSearch(){
           this.$router.push('/search')
        },
        seekPush(){
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.$router.push('/login')
            } else {
                 this.$emit('hangxiangShow')
                // this.$router.push('/seek')
                 this.$refs.seekPort.showDialogVisible()
                //   this.$emit('updataSelect')
                // this.$router.push('/user?type=2')
            }
            // console.log(this.)
           
        },
        getUserInfo(userId){
            this.$post('/user/getUserInfo',{userId:userId}).then(res =>{
                 localStorage.setItem('userInfo',JSON.stringify(res.data))
                // localStorage.setItem("token", res.data.token);
                localStorage.setItem("empId", res.data.userId);
                localStorage.setItem("username", res.data.username);
                localStorage.setItem("fillName", res.data.fillName);
                localStorage.setItem("positionName", res.data.positionName);
                localStorage.setItem("multistageDetp", res.data.multistageDetp);
                
            })
        },
        login(){
            this.$refs.login.loginDialogShow()
            // this.$router.push('/login')
        },
         register(){
              this.$refs.login.registerDialogShow()
            // this.$router.push('/register')
        },
        order(){
             let token = localStorage.getItem('token')
             if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.login()
            } else {
                  this.$emit('updataSelect')
                this.$router.push('/user?type=2')
            }
           
        },
        user(){
             let token = localStorage.getItem('token')
            if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
                this.login()
            } else {
                 this.$emit('updataSelect')
            this.$router.push('/user?type=1')
            }
           
        },
        goIndex(){
            this.$router.push('/index')
        },
        loginOut(){
            localStorage.clear();
             let token = localStorage.getItem('token')
            if(!token || token == 'null' || token == '' || token == 'undefined'){
                // next('/login')
                this.token = ''
            
            }else{
                this.token = token
                let userInfo = localStorage.getItem('userInfo')
                this.phone = JSON.parse(userInfo).user.phone
                // this.
            }
            this.$toast.success('退出登录成功');
        }
    }
}
</script>

<style scoped lang="scss">
.header{
    
// font-family:fzcjh;
    // height: 80px;
    // background: #fff;
    // display: flex;
    align-items: center;
    // justify-content: space-around;
    font-size: 16px;
    color: #333333;
    background: rgba(244, 244, 244, 1);
    // padding-left: 50px;
}
.login {
     font-size: 14px;
    color: #1677FF;
    font-weight: 500;
    align-items: center;   
}
.infoMain{
    display: flex;
    // margin-bottom: 10px;
    // height: 50px;
    // background: red;
}
.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;
}
.box1{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #F5F5F5
}
.loginOut{
    margin-top: 12px;
    display: flex;
    color: #E02020;
    align-items: center;
     justify-content: center;
}
.shouye{
    padding-right: 150px;
}
.shouye div{
    height: 80px;
    line-height: 80px;
    text-align: center;
    padding: 0 41px;
}
.backgroundClass{
    padding-bottom: 5px;
    border-bottom: 3px solid #00BAFD ;
}
.headerbox{
    width: 1180px;
    margin: 0 auto;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
}
.discenter{
    display: flex;
    align-items: center;
}
.colorCalss{
    color: #0198E3;
}
.headerTitle{
    height: 35px;
    font-size: 12px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}
.headerLogin{
    display: flex;
    align-items: center;
    color: rgba(255, 0, 0, 1);
    cursor: pointer;
}
.titleBorder{
    border-right:  1px solid #C4C4C4;;
    padding-right: 20px;
    margin-right: 10px;
}
::v-deep .el-tooltip__popper.is-light{
    border: none !important;
}
.daohang{
    background: #fff;
    
}
.daohangbox{
    width: 1188px;
    margin: 0 auto;
    display: flex;
}
.daohanglan{
    margin-top: 49px;
    font-size: 18px;
}
.daohanglan >div{
    margin-right: 16px;
    cursor: pointer;
}
.userInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.touxiang{
    width: 18px;
height: 18px;
background: #00BAFD;
 border-radius: 50%;
 color: #fff;
 line-height: 18px;
 font-size: 12px;
 text-align: center;
 cursor: pointer;
}
.phone{
    margin-left: 5px;
    text-decoration:underline;
    color: rgba(0, 186, 253, 1);
}
</style>