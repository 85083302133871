<template>
    <div class="footer">
            <div class="footerbox">
                <div style="padding-top:30px;margin-left:250px">
                    <div class="storng">
                        联系我们
                    </div>
                    <div style="margin-top:24px">
                        单位地址：浙江省杭州市西湖区灵溪北路21号3幢7层H室-2
                    </div>
               
                        <!-- 网站负责人：胡雨龙 -->
                        
                    <!-- <div style="margin-top:24px;">
                        媒体&商务合作：0755-25100671
                    </div>
                    <div style="margin-top:16px;">
                        联系电话：0755-25100671
                    </div>
                    <div style="margin-top:16px;">
                        公司总部：广东省深圳市罗湖区深南东路2010-1号五楼东
                    </div> -->
                </div>
                <div style="margin-top:30px;margin-right:270px">
                    
                    <div class="storng">
                            联系微信
                    </div>
                    <div style="margin-top:12px;" class="image">
                        <img src="./wechart.jpg" alt="" srcset="" style="width:100%">
                    </div>
                </div>
            </div>
            <div class="font">
                Copyright © 2021 Sofreight.com. All Rights Reserved <a style="margin-left:20px" href="http://beian.miit.gov.cn" target="_blank">浙ICP备2022001439号-1</a>   
                <!-- 粤公网安备44030302001115号 -->
            </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .footer{
        background: #333333;
        height: 276px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }
    .footerbox{
       width: 1122px;
    // height: 1px;
        display: flex;
        height: 215px;
        display: flex;
        color: #fff;
        justify-content: space-between;
        border-bottom: 1px solid #979797;
        padding-top: 30px;
    }
    .font{
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    .storng{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        
    }
    .image{
        margin-top: 12px;
        width: 100px;
        height: 100px;
        background: #fff;
    }
</style>