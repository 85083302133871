<template>
  <transition name="slide-fade">
    <div class="my-notify" v-if="notifyFlag">
      <div class="notify success" >
        <!-- <div class="tip">
          <span>成功</span>
        </div> -->
        <div class="fiexCenter">
           <div>
                <i class="el-icon-warning-outline"></i>
           </div>
           <div class="aler">
               提示
           </div>
            <div class="text"> 
                <div>
                    由于您未通过公司认证在海豚寻舱上将无法下单现在完成认证，即可立即解锁全部商品
                </div>
                <div style="text-align:right;margin-top:10px">
                    <span @click="goUser" type="primary" class="curos" style="margin-right:10px">立即认证</span>
                     <span @click="cancel" class="curos">取消</span>
                </div>
            </div>
        </div>
       
      </div>
    </div>
  </transition>
</template>
 <script>
 export default {
     data(){
         return{
             notifyFlag:false
         }
     },
     methods:{
         goUser(){
            this.$router.push('/user?type=1&edit=1')  
         },
         cancel(){
             this.notifyFlag = false
         },
         showNotify(){
              this.notifyFlag = true
         }
     }
 }
 </script>
<style scoped>
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}
.notify-wrap{
  
  /* background-color: #1AFA29; */
}
.my-notify{
  margin: 10px;
  width: 350px;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
}
.notify{
  position: relative;
  /* right: 10px; */
  /* padding-left: 10px; */
  width: 320px;
  /* height: 70px; */
  border-radius: 4px;
  z-index: 999;
  background: #fff;
  /* background-color:rgb(255, 244, 224); */
  /* box-shadow: -5px 5px 12px 0 rgba(204, 204, 204, .8); */
  animation: show cubic-bezier(.18,.89,.32,1.28) .4s;
}
.el-icon-warning-outline{
  color:  #1AFA29;
  font-size: 40px;
}
.error{
  border-left: 10px solid #D81E06;
}
.warning{
  border-left: 10px solid #F4EA2A;
}
.notify .tip{
  height: 30px;
  margin-bottom: 5px;
  line-height: 30px;
}
.notify .tip span{
  line-height: 30px;
  font-size: 17px;
  font-weight: 600;
}
.notify .content{
  width: 320px;
  height: 30px;
  font-size: 15px;
}
@keyframes show{
  0%{
    right: -350px;
  }
  100%{
    right: 10px;
  }
}
.fiexCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:20px
}
.aler{
 font-weight: 700;
 margin: 10px 0;
 font-size: 20px;
}
.text{
    padding-bottom: 20px;
    margin: 0 20px;
    line-height: 18px;
}
.curos{
  cursor: pointer;
  /* margin-left: 20px; */
  color: rgb(80, 227, 194);
}
</style>