import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/login/index.vue'
import seek from '../pages/seek/index.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		redirect: '/index'
	},
	{
		path: '/index',
		name: 'index',
		meta: {title: '深圳海豚寻舱科技有限公司'},
		component: () => import ('../pages/index/index')
	},
	{
		path: '/search',
		name: 'search',
		meta: {title: '航线搜索'},
		component: () => import ('../pages/index/search')
	},
	{
		path: "/login",
		name: "login",
		meta: {title: '登录'},
		component: Login
	},{
		path:'/userManagement',
		name:'userManagement',
		meta: {requireAuth: true},
		component: (resolve) => require(['../pages/user/userManagement.vue'], resolve)
	  },{
		path:'/stockManagement',
		name:'stockManagement',
		meta: {requireAuth: true},
		component: (resolve) => require(['../pages/user/stockManagement.vue'], resolve)
	  },{
		path:'/goodsDetails',
		name:'goodsDetails',
		meta: {requireAuth: true},
		component: (resolve) => require(['../pages/user/goodsDetails.vue'], resolve)
	  },{
		path:'/stockInfo',
		name:'stockInfo',
		meta: {requireAuth: true},
		component: (resolve) => require(['../pages/user/stockInfo.vue'], resolve)
	  },{
		path:'/g',
		name:'g',
		meta: {requireAuth: true},
		component: (resolve) => require(['../pages/getUrlscheme/index.vue'], resolve)
	  },
		{
			path:'/seek',
			name:"seek",
			meta:{title:'深圳海豚寻舱科技有限公司'},
			component:seek
		},
		{
			path: '/register',
			name: 'register',
			meta: {title: '注册'},
			component: () => import ('../pages/register/index')
		},{
			path: '/user',
			name: 'user',
			meta: {title: '个人中心'},
			component: () => import ('../pages/user/index')
		},{
			path: '/subOrder',
			name: 'subOrder',
			meta: {title: '提交订单'},
			component: () => import ('../pages/index/subOrder')
		},
		{
			path: '/orderInfo',
			name: 'orderInfo',
			meta: {title: '订单详情'},
			component: () => import ('../pages/orderInfo/index')
		},
		{
			path: '/successOrder',
			name: 'successOrder',
			meta: {title: '提交成功'},
			component: () => import ('../pages/index/successOrder')
		},
		{ 
			path: '/ordertip',
		    name: 'orderTip',
		    meta: {title: '深圳海豚寻舱科技有限公司'},
		    component: ()=>import('../pages/index/orderTip')
	    },
		{
			path: '/payAmount',
			name: 'payAmount',
			meta: {title: '深圳海豚寻舱科技有限公司'},
			component: () => import ('../pages/index/payAmount')
		},{
			path: '/web/index',
			name: '/web/index',
			meta: {title: '深圳海豚寻舱科技有限公司'},
			component: () => import ('../pages/web/index/index')
		},{
			path: '/web/serch',
			name: '/web/serch',
			meta: {title: '航线搜索'},
			component: () => import ('../pages/web/serch/serch')
		}
]

const router = new VueRouter({
  routes,originalPush
})

router.beforeEach((to,from,next)=>{

	if(to.meta.title){
		document.title = to.meta.title
	}
	// debugger
	if(to.path === '/login'){
		next()
	}else{
		let token = localStorage.getItem('token')
		if(!token || token == 'null' || token == '' || token == 'undefined'){
			// next('/login')
			next()
		}else{
			next()
		}
	}
})

export default router
