<template>
    <div>
        <HeaderTop></HeaderTop>
           <div class="main">
                <div class="youxuanBox">
           
                    <div class="youxuanBox">
                        <div class="hengxian">

                        </div>
                        <div style="margin-left:20px;margin-right:20px">
                            我的寻舱
                        </div>
                        <div class="hengxian">

                        </div>
                    </div>
                    
                </div>
                <div class="userBox">
                    <div style="margin-bottom:20px">
                        <el-button type="primary" @click="seekShow">我要寻舱</el-button>
                    </div>
                    <el-table
                    :data="tableData"
                    stripe
                    
                    height="500"
                    style="width: 100%">
                    <el-table-column
                    prop="date"
                    type="index"
                    label="序号"
                    align="center"
                    width="180">
                    </el-table-column>
                    <el-table-column
                    prop="date"
                    label="寻舱内容"
                     header-align="center"
                    >
                    <template slot-scope="scope">
                        <el-form label-width="110px">
                            <el-form-item label="起运港:">
                                {{scope.row.startSeaport}}
                            </el-form-item>
                            <el-form-item label="目的港:">
                                <div class="title" :title="scope.row.targetSeaport">
                                    {{ scope.row.targetSeaport }}
                                    </div>
                                <!-- {{scope.row.targetSeaport}} -->
                            </el-form-item>
                            <el-form-item label="柜型:">
                                {{scope.row.cabinetShape}}
                            </el-form-item>
                            <el-form-item label="价格预算(usd):">
                                {{scope.row.cabinetPredict}}
                            </el-form-item>
                            <el-form-item label="货好时间:">
                                {{scope.row.goodTime}}
                            </el-form-item>
                        </el-form>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="createTime"
                    label="创建时间"
                     align="center"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="date"
                    label="操作"
                     align="center"
                    >
                    <template slot-scope="scope">
                        <div >
                            <!-- 111 -->
                            <el-button type="primary" @click="xunwen(scope.row)">联系客服</el-button>
                        </div>
                    </template>
                    </el-table-column>
                    </el-table>
                    <div class="pagination">
                    <el-pagination background @current-change="sizeChange" layout="prev, pager, next,jumper" :total="total">
                    </el-pagination>
                    <div class="quedingdiv" @click="getData">
                        确定
                    </div>
                </div>
                </div>
                  
           </div>
        <seekPort ref="seekPort"  @dataget="getData"></seekPort>
        <footerBottom></footerBottom>
    </div>
</template>

<script>
import HeaderTop from '../common/header.vue'
import footerBottom from '../common/footer.vue'
import seekPort from '../common/seekPort.vue'
export default {
     components:{HeaderTop,footerBottom,seekPort},
     data(){
         return{
             total:0,
             tableData:[],
             params:{
                 pageSize:'10',
                 pageIndex:1
             }
         }
     },
     created(){
         this.getData()
     },
     mounted(){
           (function(a, b, c, d, e, j, s) {
            a[d] = a[d] || function() {
                (a[d].a = a[d].a || []).push(arguments)
            };
            j = b.createElement(c),
                s = b.getElementsByTagName(c)[0];
            j.async = true;
            j.charset = 'UTF-8';
            j.src = 'https://static.meiqia.com/widget/loader.js';
            s.parentNode.insertBefore(j, s);
        })(window, document, 'script', '_MEIQIA');
        _MEIQIA('entId', '3b5f06f295f4877f71e2e3b515160807');
        _MEIQIA('withoutBtn');
         _MEIQIA('hidePanel');
     },
    methods:{
        seekShow(){
            this.$refs.seekPort.showDialogVisible()
        },
        sizeChange(num){
            this.params.pageIndex = num
            this.getData()
        },
        getData(){
            this.$post('/freightSearch/searchFreightSearchPage',this.params).then(res =>{
                console.log(res)
                res.data.list.forEach(ele =>{
                    console.log(ele.startSeaport.join())
                    ele.startSeaport= ele.startSeaport.join()
                    ele.targetSeaport = ele.targetSeaport.join()
                })
                this.tableData = res.data.list
                this.total= res.totalCount
                
            })
        },
        xunwen(row){
             let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                _MEIQIA('metadata', {
                    contact:'姓名：' + userInfo.user.userName + '公司名称：' +  userInfo.user.shopAddress,
                    email: userInfo.user.registerMail ?  userInfo.user.registerMail : '用户未注册邮箱',
                    tel: userInfo.user.phone,
                    weibo:'',
                    comment: '用户寻舱内容' + '起始港:' + row.startSeaport + '目的港:' +row.targetSeaport
                })
                _MEIQIA('showPanel');
        }
    }
}
</script>
<style scoped lang="scss">
.userBox{
    min-height: 636px;
    box-sizing: border-box;
    background: #fff;
    // display: flex;
    padding-bottom: 40px;
     padding:20px
}
.main{
    background: #F9F9F9;
    padding-bottom: 100px;
    padding-left: 160px;
    padding-right: 160px;
}
.youxuanBox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-weight: 900;
    font-size: 20px;
   
}
.hengxian{
    width:200px;height:2px;margin:0px auto;padding:0px;background-color:#D5D5D5;overflow:hidden;
}
.pagination{
    text-align: center;
     margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 60px;
   
}
.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quedingdiv{
    width: 50px;
height: 32px;
background: #1677FF;
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
border-radius: 2px;
line-height: 32px;
text-align: center;
margin-left: 8px;
color: #fff;
cursor: pointer;
}
::v-deep .el-table--border{
    border: none !important;
    background-color:none !important
}
::v-deep .el-table::before{
      background-color:rgba(0, 0, 0, 0.4) !important
}
::v-deep .el-table--border::after{
   background-color:rgba(0, 0, 0, 0.4) !important
   
}
.title{
  overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
        cursor: pointer;
}
</style>