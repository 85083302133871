<template>
    <div>
        <el-dialog
            
            :visible.sync="dialogVisible"
            width="429px"
            :show-close="false"
            :before-close="handleClose">
           
             <transition name="slide-fade">
                    <div class="main "  v-show="loginDialog" >
                          <div class="title"  >
                        <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" style="width:295px;height:90px">
                        <img src="../../assets/images/close.png" alt="" style="width:14px;height:14px" class="cursor" @click="handleClose">
                    </div>
                    <div  style="width: 429px;" class="titlbfade">
                        <div class="LoginTitle" :class="type == 'pwdType' ? 'borderbottom' : ''" @click="type = 'pwdType'">
                            密码登录
                        </div>
                        <div class="LoginTitle" :class="type == 'messageType' ? 'borderbottom' : ''"  @click="type = 'messageType'">
                            短信登录
                        </div>
                    </div>
                        <div class="loginInput" v-show="type == 'pwdType'">
                           
                            <div style="margin-top:20px" class="input" :class="loginClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="loginClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/phone.png" alt="" srcset="">
                                </div>
                                <div style="width: 273px">
                                <input type="text" v-model="phone" placeholder="请输入手机号" @focus="loginClass('loginClassActive')" @blur="loginClassBlur('loginClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="messageClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="messageClassActive ? 'phoneIcon' : ''">
                                        <img src="../../assets/images/pwd.png" alt="" srcset="" style="width:27px;height:27px">
                                    </div>
                                    <div style="width: 273px; position: relative;"  >
                                    <input type="password" v-model="phoneCode" placeholder="请输入密码" @focus="loginClass('messageClassActive')" @blur="loginClassBlur('messageClassActive')">
                                </div>
                            </div>
                           <span style="margin-top:10px;display:block" class="pass">
                              <div>
                                    <el-checkbox v-model="checked">记住密码</el-checkbox>
                              </div>
                              <div class="wj" @click="wangji">
                                  忘记密码
                              </div>
                           </span>
                            <!-- <div> -->
                                <!-- <el-radio v-model="radio" label="1">记住密码</el-radio> -->
                            <!-- </div> -->
                        </div>
                        <div class="loginInput" v-show="type == 'messageType'">
                           
                            <div style="margin-top:20px" class="input" :class="loginClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="loginClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/phone.png" alt="" srcset="">
                                </div>
                                <div style="width: 273px">
                                <input type="text" v-model="phone" placeholder="请输入手机号" @focus="loginClass('loginClassActive')" @blur="loginClassBlur('loginClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="messageClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="messageClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/message.png" alt="" srcset="" style="width:33px;height:21px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="text" v-model="phoneCode" placeholder="请输入验证码" @focus="loginClass('messageClassActive')" @blur="loginClassBlur('messageClassActive')">
                                <div class="geCodeText" @click="getCode">
                                    {{codeText}}
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="loginBtn" @click="login" >
                            登录
                        </div>
                        <div class="noAct" @click="goRegister">
                            没有账号？点击注册
                        </div>
                    </div>
            </transition>
             <transition name="slide-fade">
                    <div class="main" v-show="registerDialog">
                          <div class="title"  v-if="registerDialog">
                        <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" style="width:295px;height:90px">
                        <img src="../../assets/images/close.png" alt="" style="width:14px;height:14px" class="cursor" @click="handleClose">
                    </div>
                        <div  style="width: 429px;">
                        <div class=" regTitle">
                            欢迎注册海豚寻舱！
                        </div>
                        </div>
                        <div class="loginInput">
                            <!-- {{loginClassActive}} -->
                            <div style="margin-top:20px" class="input" :class="loginClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="loginClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/phone.png" alt="" srcset="">
                                </div>
                                <div style="width: 273px" >
                                <input type="text" v-model="phone" placeholder="请输入手机号" @focus="loginClass('loginClassActive')" @blur="loginClassBlur('loginClassActive','registerPhone')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="messageClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="messageClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/message.png" alt="" srcset="" style="width:33px;height:21px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="text" v-model="phoneCode" placeholder="请输入验证码" @focus="loginClass('messageClassActive')" @blur="loginClassBlur('messageClassActive')">
                                <div class="geCodeText" @click="getCodereg">
                                    {{codeText}}
                                </div>
                                </div>
                            </div>
                             <div style="margin-top:17px" class="input" :class="pwdClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="pwdClassActive ? 'phoneIcon' : ''">
                                        <img src="../../assets/images/pwd.png" alt="" srcset="" style="width:27px;height:27px">
                                    </div>
                                    <div style="width: 273px; position: relative;"  >
                                    <input type="password" v-model="pwd" placeholder="请输入密码" @focus="loginClass('pwdClassActive')" @blur="loginClassBlur('pwdClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="nameClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="nameClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/name.png" alt="" srcset="" style="width:30px;height:30px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="text" v-model="name" placeholder="请输入联系人姓名" @focus="loginClass('nameClassActive')" @blur="loginClassBlur('nameClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="companyClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="companyClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/company.png" alt="" srcset="" style="width:31px;height:30px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="text" v-model="company" placeholder="请输入公司全称" @focus="loginClass('companyClassActive')" @blur="loginClassBlur('companyClassActive')">
                                </div>
                            </div>
                        </div>

                        <div class="loginBtn" @click="registerUserByPass" style="margin-top:16px">
                            登录
                        </div>
                        <div class="noAct" @click="goLogin" style="margin-top:23px">
                            已有账号，点击登录
                        </div>
                    </div>
             </transition>
            <transition name="slide-fade">
                    <div class="main" v-show="resetDialog">
                          <div class="title"  v-if="resetDialog">
                        <img src="https://yundian2.oss-cn-shanghai.aliyuncs.com/upload/upload/164247503883118.png" alt="" style="width:295px;height:90px">
                        <img src="../../assets/images/close.png" alt="" style="width:14px;height:14px" class="cursor" @click="handleClose">
                    </div>
                        <div  style="width: 429px;">
                        <div class=" regTitle" style="color:#333">
                            重设密码
                        </div>
                        </div>
                        <div class="loginInput">
                            <!-- {{loginClassActive}} -->
                            <div style="margin-top:20px" class="input" :class="loginClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="loginClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/phone.png" alt="" srcset="">
                                </div>
                                <div style="width: 273px" >
                                <input type="text" v-model="phone" placeholder="请输入手机号" @focus="loginClass('loginClassActive')" @blur="loginClassBlur('loginClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="messageClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="messageClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/message.png" alt="" srcset="" style="width:33px;height:21px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="text" v-model="phoneCode" placeholder="请输入验证码" @focus="loginClass('messageClassActive')" @blur="loginClassBlur('messageClassActive')">
                                <div class="geCodeText" @click="getCode">
                                    {{codeText}}
                                </div>
                                </div>
                            </div>
                             <div style="margin-top:17px" class="input" :class="pwdClassActive ? 'loginClassActive' : ''">
                                <div class="inputIcon" :class="pwdClassActive ? 'phoneIcon' : ''">
                                        <img src="../../assets/images/pwd.png" alt="" srcset="" style="width:27px;height:27px">
                                    </div>
                                    <div style="width: 273px; position: relative;"  >
                                    <input type="password" v-model="pwd" placeholder="请输入密码" @focus="loginClass('pwdClassActive')" @blur="loginClassBlur('pwdClassActive')">
                                </div>
                            </div>
                            <div style="margin-top:17px" class="input" :class="nameClassActive ? 'loginClassActive' : ''">
                            <div class="inputIcon" :class="nameClassActive ? 'phoneIcon' : ''">
                                    <img src="../../assets/images/twoPwd.png" alt="" srcset="" style="width:31px;height:15px">
                                </div>
                                <div style="width: 273px; position: relative;"  >
                                <input type="password" v-model="twoPwd" placeholder="请再次输入重设密码" @focus="loginClass('nameClassActive')" @blur="loginClassBlur('nameClassActive')">
                                </div>
                            </div>
                        </div>

                        <div class="loginBtn" @click="restUserByPass" style="margin-top:16px" v-loading="loading">
                            登录
                        </div>
                    </div>
             </transition>
        </el-dialog>
    </div>
</template>
<script>
import Md5 from 'js-md5';
export default {
    data(){
        return{
            dialogVisible:false,
           phone:'',
           loginClassActive:false,
           messageClassActive:false,
           nameClassActive:false,
           companyClassActive:false,
           codeText:'获取验证码',
           phoneCode:'',
           loginDialog:false,
           registerDialog:false,
           resetDialog:false,
           name:'',
           pwd:'',
           company:'',
           loading:true,
           type:'messageType',
           twoPwd:'',
           pwdClassActive:false,
        //    radio:'0'
        checked:false
        }
    },
    methods:{
        wangji(){
            this.resetDialog = true
            this.loginDialog = false
        },
        handleClose(){
            this.dialogVisible = false
        },
        goRegister(){
            // const remind = document.getElementById("remind");
            // remind.class = "fade-out";
            // setTimeout(() => {
                this.loginDialog = false
                this.registerDialog = true
            // }, 2000);
    //   debugger
        //     this.loginDialog = false,
        //    this.registerDialog = true
        },
        goLogin(){
            this.loginDialog = true,
            this.registerDialog = false
        },
        loginDialogShow(){
            this.dialogVisible = true
            this.loginDialog = true
            this.registerDialog = false
            this.resetDialog = false
        },
        registerDialogShow(){
            this.dialogVisible = true
            this.registerDialog = true
            this.loginDialog = false
        },
        loginClass(type){
             if(type == 'loginClassActive'){
                  this.loginClassActive = true
             }
             if(type == 'messageClassActive'){
                this.messageClassActive = true
             }
            if(type == 'nameClassActive'){
                this.nameClassActive = true
            }
            if(type == 'companyClassActive'){
                this.companyClassActive = true
            }
             if(type == 'companyClassActive'){
                this.companyClassActive = true
            }
            if(type== 'pwdClassActive'){
                this.pwdClassActive = true
            }
        
        },
        loginClassBlur(type,val){
             if(type == 'loginClassActive'){
                  this.loginClassActive = false
             }
             if(type == 'messageClassActive'){
                this.messageClassActive = false
             }
              if(type == 'nameClassActive'){
                this.nameClassActive = false
            }
            if(type == 'companyClassActive'){
                this.companyClassActive = false
            }
            if(type== 'pwdClassActive'){
                this.pwdClassActive = false
            }
            if(val=='registerPhone'){
                console.log(this.phone.length)
                if(this.phone.length != 11){
                    this.$message.error("请输入正确的手机号")
                }
                // if()
            }
        },
        registerUserByPass(){
            if(this.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
             if(this.pwd == ''){
                this.$toast.fail('请输入密码');
                return false
            }
            if(this.phoneCode == ''){
                this.$toast.fail('请输入验证码');
                return false
            }
            
        
            if(this.name == ''){
                this.$toast.fail('请输入联系人姓名');
                return false
            }
            if(this.company == ''){
                this.$toast.fail('请输入公司全称');
                return false
            }
           
        
            let params = {
                phone:this.phone,
                phoneCode:this.phoneCode
            }
             params.name = this.name
            params.company = this.company
            params.phoneCode = Md5(this.phoneCode)
            params.pwd = Md5(this.pwd)
            this.$post('/user/registerUserByPass',params).then(res =>{
                if(res.code == '200'){
                  
                         this.$message.success('注册成功')
                 
                   
                    localStorage.setItem('userInfo',JSON.stringify(res.data))
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("empId", res.data.userId);
                    localStorage.setItem("username", res.data.username);
                    localStorage.setItem("fillName", res.data.fillName);
                    localStorage.setItem("positionName", res.data.positionName);
                    localStorage.setItem("multistageDetp", res.data.multistageDetp);
                    this.dialogVisible = false
                    this.$emit("info")
                }else{
                    this.$toast.fail(res.msg)
                }
            })
        },
        restUserByPass(){
            if(this.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
            if(this.phoneCode == ''){
                this.$toast.fail('请输入验证码');
                return false
            }
            if(this.pwd ==''){
                 this.$toast.fail('请输入密码');
                return false
            }
              if(this.twoPwd ==''){
                 this.$toast.fail('请再次输入密码');
                return false
            }
            if(this.pwd != this.twoPwd){
                 this.$toast.fail('两次密码输入不一致');
                return false
            }
             let params = {
                phone:this.phone,
                phoneCode:this.phoneCode
            }
             params.phoneCode = Md5(this.phoneCode)
             params.pwd = Md5(this.pwd)
               this.$post('/user/setpassbycode',params).then(res =>{
                if(res.code == '200'){
                    
                         this.$message.success('修改密码成功已为您登录')
                    
                   
                    localStorage.setItem('userInfo',JSON.stringify(res.data))
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("empId", res.data.userId);
                    localStorage.setItem("username", res.data.username);
                    localStorage.setItem("fillName", res.data.fillName);
                    localStorage.setItem("positionName", res.data.positionName);
                    localStorage.setItem("multistageDetp", res.data.multistageDetp);
                    this.dialogVisible = false
                    this.$emit("info")
                }else{
                    this.$toast.fail(res.msg)
                }
            })
            // /user/setpassbycode
        },
         login(){
             if(this.type == 'pwdType'){
                //  password
                 let params = {
                    phone:this.phone,
                    pwd: Md5(this.phoneCode)
                }
                  this.$post('/user/passUserByPhone',params).then(res =>{
                        if(res.code == '200'){
                            // if(this.registerDialog == true){
                            //     this.$message.success('注册成功')
                            // } else {
                                this.$message.success('登录成功')
                            // }
                        
                            localStorage.setItem('userInfo',JSON.stringify(res.data))
                            localStorage.setItem("token", res.data.token);
                            localStorage.setItem("empId", res.data.userId);
                            localStorage.setItem("username", res.data.username);
                            localStorage.setItem("fillName", res.data.fillName);
                            localStorage.setItem("positionName", res.data.positionName);
                            localStorage.setItem("multistageDetp", res.data.multistageDetp);
                            this.dialogVisible = false
                            this.$emit("info")
                        }else{
                            this.$toast.fail(res.msg)
                        }
                    })
                // this.$post('/user/passUserByPhone')
                //   params.phoneCode = Md5(this.phoneCode)
                 return false
             }
            if(this.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
            if(this.phoneCode == ''){
                this.$toast.fail('请输入验证码');
                return false
            }
           
            // let Md5
            let params = {
                phone:this.phone,
                phoneCode:this.phoneCode
            }
            if(this.registerDialog === true){
                if(this.name == ''){
                    this.$toast.fail('请输入联系人姓名');
                    return false
                }
                if(this.company == ''){
                    this.$toast.fail('请输入公司全称');
                    return false
                }
                params.name = this.name
                params.company = this.company
           }
            params.phoneCode = Md5(this.phoneCode)
            console.log(params)
            // return false
            this.$post('/user/registerByPhone',params).then(res =>{
                if(res.code == '200'){
                    if(this.registerDialog == true){
                         this.$message.success('注册成功')
                    } else {
                         this.$message.success('登录成功')
                    }
                   
                    localStorage.setItem('userInfo',JSON.stringify(res.data))
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("empId", res.data.userId);
                    localStorage.setItem("username", res.data.username);
                    localStorage.setItem("fillName", res.data.fillName);
                    localStorage.setItem("positionName", res.data.positionName);
                    localStorage.setItem("multistageDetp", res.data.multistageDetp);
                    this.dialogVisible = false
                    this.$emit("info")
                }else{
                    this.$toast.fail(res.msg)
                }
            })
        },
        // login(){
        //     this.
        // },
         getCodereg(){
            const TIME_COUNT = 60
            if(this.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        this.disabled = true
                        this.codeText = this.count + 'S后获取'
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false
                        this.codeText='获取验证码';
                    }
                }, 1000)
            }
            this.$post('/user/sendPhoneCodeLogin ',({phone:this.phone})).then(res =>{
                    if(res.code == '200'){

                    }
            })
            // /user/sendPhoneCode
            // api
        },
         getCode(){
            const TIME_COUNT = 60
            if(this.phone == ''){
                this.$toast.fail('请输入手机号');
                return false
            }
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        this.disabled = true
                        this.codeText = this.count + 'S后获取'
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false
                        this.codeText='获取验证码';
                    }
                }, 1000)
            }
            this.$post('/user/sendPhoneCode',({phone:this.phone})).then(res =>{
                    if(res.code == '200'){

                    }
            })
            // /user/sendPhoneCode
            // api
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header{
    padding: 0 !important;
    border-radius: 12px 12px 12px 12px;
    // position: absolute;
    // height: 100%;
}
::v-deep .el-dialog__body{
    padding: 0 !important;
}
::v-deep .el-dialog{
   border-radius: 12px 12px 12px 12px; 
}
::v-deep .el-dialog__body{
     border-radius: 12px 12px 12px 12px !important; 
}
.titlbfade{
    display: flex;
    margin-top: 20px;
}
.pass{
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.pass >div{
    // width: 50%;
}
.title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 29px 0 25px;
    width: 429px;
    border-radius: 12px 12px 12px 12px;
    // position: absolute;
    // height: 100%;
}
.LoginTitle{
   font-size: 24px;
    margin-left: 25px;
    display: flex;
    
    width: 98px;
    
    
    padding-bottom: 5px;
    cursor: pointer;
}
.borderbottom{
    border-bottom:  3px solid #000000;
}
.regTitle{
     font-size: 24px;
    margin-left: 25px;
    display: flex;
    color: rgba(0, 186, 253, 1);
    width: 100% !important;
    margin-top: 20px;
}
.main{
    // margin-left: 28px;
    font-weight: 400;
    color: #000000;
    color: #333;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background: #fff;
    top: 89px;
     border-radius:12px; 
}
.noAct{
    margin-top: 76px;
    font-size: 14px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #6C6C6C;
text-decoration: underline;
cursor: pointer;
}
.loginBtn{
    width: 323px;
    height: 43px;
    background: #00BAFD;
    border-radius: 6px 6px 6px 6px;
    color: rgba(255, 255, 255, 1);
    font-size:18px;margin-top:60px;
    line-height: 43px;
    text-align: center;
    cursor: pointer;
}
.loginInput >div{
   width: 323px;
height: 50px;
border-radius: 6px 6px 6px 6px;
opacity: 1;
box-sizing: border-box;
border: 1px solid #C4C4C4;
}
.input{
    display: flex;
    align-items: center;
   
}
// .input :active{

//     border: 1px solid #00BAFD;
// }
input {
     outline-style: none ;
     border: 1px solid #ccc; 
}
.inputIcon{
    height: 50px;
    width: 50px;
   
background: #C4C4C4;
display: flex;
align-items: center;
justify-content: center;
}
.input img{
    width: 21px;
    height: 29px;

}
input{  
	background:none;  
	outline:none;  
	border:1px solid #ccc;
}
input:focus{   
	border:none;
}
input{  
	background:none;  
	outline:none;  
	border:none;
    height: 50px;
    width: 100%;
    margin-left: 9px;
}
.loginClassActive{
    border:1px solid #00BAFD !important;
    
}
.geCodeText{
    position: absolute;
    right: 5px;
    top: 20px;
font-weight: 400;
color: #6C6C6C;
font-size: 14px;
cursor: pointer;
}
.phoneIcon{
    background: #00BAFD;

    box-shadow: 0px 0px 10px 1px #00BAFD;
}
.v-enter,
    .v-leave-to {
      opacity: 0;
      transform: translateX(150px);
    }

    /* v-enter-active 【入场动画的时间段】 */
    /* v-leave-active 【离场动画的时间段】 */
    .v-enter-active,
    .v-leave-active{
      transition: all 0.8s ease;
    }
::v-deep .v-modal{
    background: RGBA(0, 0, 0, 0) !important;
}
.fade-out {
	-webkit-animation: fade-out 1s 1s ;
	        animation: fade-out 1s 1s ;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-3-14 10:40:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  80%{
      
  }
  100% {
       opacity: 0;
    //   display: none;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  80%{
       
  }
  100% {
      opacity: 0;
    //   display: none;
  }
}
@keyframes fade-enter {
  0% {
    opacity: 1;
  }
  80%{
       
  }
  100% {
      opacity: 1;
    //   display: none;
  }
}
.wj{
    cursor: pointer;
}
// .slide-fade-enter-active {
//   transition: all .3s ease;
// }
// .slide-fade-leave-active {
//   transition: all .8s 
// }
// .slide-fade-enter, .slide-fade-leave-to
// /* .slide-fade-leave-active for below version 2.1.8 */ {
// //   transform: translateX(10px);
//   opacity: 0;
// }
</style>