import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const showMenuTemp = localStorage.getItem("showMenu");
const menuIndexTemp = localStorage.getItem("menuIndex");
const projectName = localStorage.getItem("projectName");
const authList = localStorage.getItem('authList')?JSON.parse(localStorage.getItem('authList')):[];

export default new Vuex.Store({
  state: {
    showMenu: showMenuTemp,   //存库词条的变量
    menuIndex: menuIndexTemp,
    projectName: projectName,
    activeName:'',
    openNames:[],
    loginInfo:{},
    authList:authList,
  },
  mutations: {   
          //修改数据仓库的事件
    changeLoginInfo(state,value){
      state.loginInfo = value
    },
    changeValue(state,value){
      state.showMenu = value
    },
    changeIndex(state,value){
      state.menuIndex = value
    },
    changeProjectName(state,value){
      state.projectName = value
    },
    changeActiveName(state,value){
      state.activeName = value
    },
    changeOpenName(state,value){
      state.openNames = value
    },
    changeAuthList(state,value){
      state.authList = value
    },
  },
  actions: {
  },
  modules: {
  }
})
