<template>
  <div
    @click="stylePortClick"
    v-show="dialogVisible"
    class="dialog"
    :class="!stylePort ? 'dislog' : ''"
  >
    <!-- <el-dialog
        title="航线提醒："
        :visible.sync="dialogVisible"
        width="500px"
       
        :before-close="handleClose"> -->

    <div class="dialogMain">
      <div class="dialogHeader">
        <span style="color: #00bafd" class="el-dialog__title">我要寻舱</span
        ><i
          @click="handleClose"
          class="el-icon-close"
          style="font-size: 18px"
        ></i>
      </div>
      <div style="height: 520px">
        <!-- <div style="text-align:center;color:#ACACAC">
               提交寻舱后,海豚寻舱会自动帮助您寻找价格最低的舱位
          </div> -->
        <el-form label-width="130px" >
          <el-form-item label="出发地：" required >
            <div class="post">
              <!-- v-show="!stylePort" -->
              <el-cascader
                filterable
                v-model="startPortJsonValue"
                :options="startPortJson"
                :props="props"
                :show-all-levels="false"
                popper-class="popper-class"
                class="maicang"
                @change="bindChange"
                clearable
              ></el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="目的地：" required>
            <div class="post">
              <!-- v-show="!stylePort" -->
              <el-select
                @visible-change="visibleChange"
                ref="startSeaportVisible"
                @change="blurChange"
                v-model="startSeaport"
                multiple
                collapse-tags
                @remove-tag="removeTag"
                filterable
                clearable
                :filter-method="getFreightPort"
                placeholder="请输入目的港"
              >
                <!-- <div > -->
                <el-option
                  v-for="item in FreightPortList1"
                  :key="item.id"
                  :label="item.cname"
                  :value="item.cname"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.cname
                  }}</span>
                </el-option>
                <!-- </div> -->
              </el-select>
              <div
                class="stylePort"
                :tabindex="1"
                v-show="stylePort"
                @blur.stop="stylePortBlur"
                @click.stop="zuzhi"
              >
                <div class="pordtTitle">
                  <div
                    v-for="(item, index) in portJson"
                    :key="index"
                    class="jsonTitle"
                    :class="JsonTitleIndex == index ? 'titleborder1' : ''"
                    @click.stop="JsonTitleIndex = index"
                    @click="titleCheckBox(index)"
                  >
                    {{ item.title }}
                    <!-- <el-checkbox v-model="itdem.titleCheckBox" @change="titleCheckBox(index)"><span class="jsonTitle" style="padding-left:0;padding-right:0">{{item.title}}</span></el-checkbox> -->
                  </div>
                </div>
                <div class="portJsonSmallbox">
                  <div
                    v-for="(prot, portIndex) in portJson[JsonTitleIndex].json"
                    :key="portIndex"
                  >
                    <div class="portJsonSmall">
                      {{ prot.smallTitle }}
                    </div>
                    <ul class="smallJson clearfix">
                      <li
                        v-for="(small, smallindex) in prot.json"
                        :key="smallindex"
                      >
                        <span
                          href=""
                          :title="small.name + small.cname"
                          style="text-align: left"
                        >
                          <!-- <span :class="small.name == targetSeaport ? 'selectSmall' :''"> -->
                          <el-checkbox
                            max="2"
                            :disabled="
                              startSeaport.length == 2 && !small.smallCheckBox
                            "
                            v-model="small.smallCheckBox"
                            @change="smallCheckBox(portIndex, smallindex)"
                          >
                            <!-- {{startSeaport.length == 2 && !small.smallCheckBox}} -->
                            <div>{{ small.name }}({{ small.cname }})</div>
                          </el-checkbox>
                          <!-- </span> -->
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="柜型：">
            <el-select
              v-model="jiangjia.cabinetShape"
              clearable
              placeholder="柜型"
            >
              <el-option
                v-for="item in getFreightDefineEnumsList.boxModes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="船司：">
            <el-select
              v-model="jiangjia.shipCompanyName"
              clearable
              placeholder="船司"
            >
              <el-option
                    v-for="item in companyList1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cname }}</span>
                    </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="货好时间：">
            <el-date-picker
              v-model="goodTime"
              type="date"
              align="right"
              :picker-options="pickerOptions"
              placeholder="选择货好时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="价格预算： ">
            <el-input
            style="width:153px"
              :controls="false"
              v-model="jiangjia.cabinetPredict"
              placeholder="请输入价格预算"
            ></el-input>
            <span style="font-size:14px">&nbsp;&nbsp;(USD)</span>
          </el-form-item>
          <el-form-item label="合约要求：">
            <el-checkbox-group v-model="contractRequire">
              <el-checkbox label="BCO约" ></el-checkbox>
              <el-checkbox label="NVO约" ></el-checkbox>
              <el-checkbox label="出单无限制" ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="品名：" prop="name">
            <el-input
             style="width:100px"
              v-model="productName"
              placeholder="品名"
              clearable
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他备注：">
            <el-input
             style="width:150px"
              placeholder="请输入备注"
              v-model="remarks"
              clearable
              maxlength="20"
            >
            </el-input>
          </el-form-item>
            <div class="seekDiv">
              
              <div>
                <img
                  src="./wechart.jpg"
                  alt=""
                  srcset=""
                  v-show="empQrCodeUrl == ''"
                  style="width: 126px; height: 126px"
                />
                <img
                  :src="empQrCodeUrl"
                  alt=""
                  srcset=""
                  v-show="empQrCodeUrl != ''"
                  style="width: 126px; height: 126px"
                />
              </div>
              <div style="color: #acacac;margin-left:10px">
                <br />添加寻舱专属客服微信。<br />在寻舱过程中遇到任何问题<br />可以联系客服咨询
              </div>
            </div>
        </el-form>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: end"
      >
        <el-button
          type="primary"
          @click="conformSeek"
          :loading="confirmLoading"
          style="
            width: 82px;
            height: 33px;
            background-color: #00bafd;
            border: 1px solid #00bafd;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          >确定</el-button
        >
        <el-button
          @click="handleClose"
          style="
            width: 82px;
            height: 33px;
            border: 1px solid #00bafd;
            color: #00bafd;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          >取 消</el-button
        >
      </div>
    </div>

    <!-- </el-dialog>  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      props: {
        multiple: true,
        value: "smallTitle",
        label: "smallTitle",
        children: "json",
        emitPath: false,
        checkStrictly: true,
      },
      confirmLoading: false,
      value2: [],
      dialogVisible: false,
      JsonTitle: [],
      portJson: [
        {
          json: [],
        },
      ],
      startPortJson: [],
      startPortJsonValue: [],
      getFreightDefineEnumsList: [],
      FreightPortList1: [],
      startSeaport: [],
      goodTime: "",
      jiangjia: {
        cabinetPredict: "",
        cabinetShape: "",
        shipCompanyName:''
      },
      stylePort: false,
      JsonTitleIndex: 0,
      JsonStartTitleIndex: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //禁止选择今天以前的时间
        },
      },
      disabled: false,
      titleCheckBoxIndex: 0,
      startSeaportArr: [],
      dislog: false,
      name: "",
      contractRequire: [],
      remarks: "",
      productName: "",
      companyList1:[],
      empQrCodeUrl:''
    };
  },
  created() {
    this.getFreightDefineEnums();
    this.getFreightPortJson();
    this.getFreightPort();
    this.getFreightStartPortJson();
    this.getFreightCompany1()
  },
  watch: {
    startSeaport(oldName, newName) {
      console.log(this.startSeaport);
    },
  },
  methods: {
      getFreightCompany1(val){
            this.$post('/system/getFreightCompany',{name:val}).then(res =>{
                this.companyList1= res.data.list
            })
        },
    bindChange(e) {
      // console.log(e.length)
      if (e.length == 2) {
        let startPortJson = this.startPortJson;
        startPortJson.forEach((ele) => {
          ele.disabled = false;
          ele.json.forEach((ele1) => {
            // console.log(ele1)
            this.startPortJsonValue.forEach((ele3) => {
              // console.log(ele1.cname == ele3)
              if (ele1.type == "1" || !ele1.type) {
                if (ele1.cname == ele3) {
                  ele1.disabled = false;
                  ele1.type = "2";
                } else {
                  ele1.disabled = true;
                  ele1.type = "1";
                }
              }
            });
          });
        });

        this.$set(this.startPortJson, "startPortJson", startPortJson);
        // })
        console.log("startPortJson", this.startPortJson);
      } else {
        let startPortJson = this.startPortJson;
        startPortJson.forEach((ele) => {
          // console.log(ele)
          ele.disabled = true;
          ele.json.forEach((ele1) => {
            ele1.disabled = false;
            ele1.type = "1";
          });
        });
        this.startPortJson = startPortJson;
        this.$set(this.startPortJson, "startPortJson", startPortJson);
      }
    },
    removeTag() {
      console.log("remove");
      // this.startSeaport = []
      let a = this.startSeaport;
      // a.forEach(ele =>{
      this.portJson.forEach((ele1) => {
        ele1.json.forEach((ele2) => {
          ele2.json.forEach((ele3) => {
            try {
            } catch (error) {}
          });
        });
      });
    },
    zuzhi() {},
    conformSeek() {
      console.log(this.startPortJsonValue, this.goodTime);
      if (this.startPortJsonValue.length == 0) {
        this.$message.error("请至少选择一个出发地");
        return;
      }
      if (this.startSeaport.length == 0) {
        this.$message.error("请至少选择一个目的地");
        return;
      }
      //   if (this.jiangjia.cabinetPredict == "") {
      //     this.$message.error("请输入价格预算");
      //     return;
      //   }
      //   if (this.goodTime == null || this.goodTime == "") {
      //     this.$message.error("请输入货好时间");
      //     return;
      //   }
      this.$confirm("是否提交当前航线数据？, 是否继续?", "提示", {
        //    this.$post()
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then((res) => {
        this.confirmLoading = true;
        let val = [];
        this.startPortJsonValue.forEach((ele) => {
          val.push(ele);
        });

        let params = {
          startSeaport: val,
          targetSeaport:
            this.startSeaportArr.length == 0
              ? this.startSeaport
              : this.startSeaportArr,
          goodTime: this.goodTime,
          cabinetPredict: this.jiangjia.cabinetPredict,
          cabinetShape: this.jiangjia.cabinetShape,
          shipCompanyName:this.jiangjia.shipCompanyName,
          remarks: this.remarks,
          productName: this.productName,
        };
        if (this.contractRequire.length != 0) {
          params.contractRequire = this.contractRequire.join();
        }
        this.$post("/freightSearch/addFreightSearch", params).then((res) => {
          console.log(res);
          this.confirmLoading = false;
          if (res.code == "200") {
            this.$emit("dataget");
            this.$message.success("提交成功，请到个人中心查看寻舱记录");
            // this.$router.push('/user?type=3')
            this.handleClose();
            //    this.dialogVisible = false
          }
        });
      });
    },
    getFreightPort(val) {
      // this.startSeaport = val
      this.$post("/system/getFreightPort", { type: "target", name: val }).then(
        (res) => {
          this.stylePort = false;
          this.FreightPortList1 = res.data.list;
        }
      );
    },
    getFreightPortJson() {
      this.$post("/system/getFreightPortJson1").then((res) => {
        res.data.list.forEach((ele) => {
          ele.json.forEach((ele1) => {
            ele1.json.forEach((ele2) => {
              ele2.smallCheckBox = false;
            });
          });
        });
        this.portJson = res.data.list;
        this.JsonTitle = res.data.list[0].title;
      });
    },
    getFreightStartPortJson() {
      this.$post("/system/getFreightStartPortJson").then((res) => {
        this.startPortJson = [];

        let a = [];

        res.data.list[0].json.forEach((ele) => {
          ele.disabled = true;
          // ele.popperClass = 'popper-class'
          ele.json.forEach((ele1) => {
            ele1.smallTitle = ele1.cname;
            // ele.cname = ele.cname
          });
        });
        this.startPortJson = res.data.list[0].json;
        console.log(a);
      });
    },
    showDialogVisible(val, val1) {
  
      this.dialogVisible = true;
      // empQrCodeUrl
      if(localStorage.getItem('userInfo')){
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          if(userInfo.bindEmp){
             this.empQrCodeUrl = userInfo.bindEmp.empQrCodeUrl
          }
      }
      this.$nextTick(() => {
        if (val) {
          this.startPortJsonValue = [val];
        }
        if (val1) {
          this.startSeaport = [val1];
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("zindehide");
      this.startPortJsonValue = [];
      this.startSeaport = [];
      this.jiangjia.cabinetPredict = "";
      this.jiangjia.cabinetShape = "";
      this.goodTime = "";
      this.getFreightPortJson();
      this.getFreightPort();
      this.getFreightStartPortJson();
    },
    getFreightDefineEnums() {
      this.$get("/system/getFreightDefineEnums").then((res) => {
        this.getFreightDefineEnumsList = res.data;
      });
    },
    visibleChange(bool) {
      // debugger

      if (bool) {
        this.stylePort = bool;
        this.dislog = bool;
      } else {
        // this.stylePort = bool
      }
      console.log(bool);
    },
    visibleChangeStart(bool) {
      if (bool) {
        this.startStylePort = bool;
      }
    },
    stylePortClick() {
      // alert('11')
      this.stylePort = false;
      // this.dialogVisible = false
      this.startStylePort = false;
    },
    stylePortBlur() {
      // this.stylePort = false
    },
    blurChange() {
      console.log(111);
      this.stylePort = false;
      this.startStylePort = false;
    },

    titleCheckBox(index) {
      let value = this.portJson[index];
      this.startSeaport = [];
      this.titleCheckBoxIndex = index;
      this.disabled = true;

      this.portJson[index] = value;
      return false;
      this.wathcportJson();
    },
    smallCheckBox(portIndex, smallIndex) {
      this.titleCheckBoxIndex = this.JsonTitleIndex;
      // return false
      // this.disabled = true
      // console.log(this.portJson[this.JsonTitleIndex].json[portIndex].json[smallIndex])
      if (
        !this.portJson[this.JsonTitleIndex].json[portIndex].json[smallIndex]
          .smallCheckBox
      ) {
        this.portJson[this.JsonTitleIndex].json[portIndex].json[
          smallIndex
        ].smallCheckBox = false;
      } else {
        this.portJson[this.JsonTitleIndex].json[portIndex].json[
          smallIndex
        ].smallCheckBox = true;
      }
      // return false

      this.wathcportJson();
    },
    wathcportJson() {
      this.startSeaport = [];
      let name = "";
      let str = "";
      let chuwai = [];
      this.startSeaportArr = [];
      this.portJson.forEach((ele) => {
        if (ele.titleCheckBox == true) {
          this.startSeaport.push(ele.title);

          ele.json.forEach((ele1) => {
            ele1.json.forEach((ele2) => {
              if (!ele2.smallCheckBox) {
                chuwai.push(ele2.cname);
              }
            });
          });
        } else {
          ele.json.forEach((ele1) => {
            ele1.json.forEach((ele2) => {
              if (ele2.smallCheckBox) {
                this.startSeaport.push(ele2.cname);
              }
            });
          });
        }
      });
      if (chuwai.length != 0) {
        if (chuwai.length == 1) {
          str = this.startSeaport[0];
          name = this.startSeaport[0] + "(" + chuwai.join() + ")除外";
          this.startSeaport = [name];
        } else {
          // let length11 = chuwai.length - 1
          str = this.startSeaport[0];
          // console.log('(' + chuwai[0] + '等' + chuwai.length - 1 +'港口'  + ')除外',chuwai[0])
          name =
            this.startSeaport[0] +
            "(" +
            chuwai[0] +
            "等" +
            chuwai.length +
            "港口" +
            ")除外";
          this.startSeaport = [name];
        }
        str = str + "(" + chuwai.join() + ")除外";
        this.startSeaportArr = [str];
      }
      if (this.startSeaport.length == 0) {
        this.disabled = false;
      }
      if (this.startSeaport.length == 1) {
        // this.
        // if(this.portJson)
        // this.portJson.forEach(ele =>{
        //     ele.
        // })
        console.log(this.portJson, "portJson");
      }
      console.log("startSeaportArr", this.startSeaport.length);
      // console.log(name,this.stylePort)
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  // filter:alpha(opacity=60);  /*设置透明度为60%*/
  // opacity:0.1;  /*非IE浏览器下设置透明度为60%*/
  // display:none;
}
.dialogMain {
  padding: 10px 50px 30px;
  width: 500px;
  padding-left: 0;
  position: relative;
  z-index: 999;
  background: #fff;
  // border-radius: 12px;
}
.dialog-footer {
  text-align: right;
}
::v-deep .el-input--suffix {
  width: 302px ;
}
.stylePort {
  position: absolute;
  z-index: 99999 !important;
  width: 600px;
  display: block;
  height: 360px;
  box-shadow: 0px 1px 3px #666;
  background: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.jsonTitle{
    display: inline-block;
//    height: 26px;
   text-align: center;
   line-height: 26px;
//    padding: 0 12px;
   margin-right: 15px;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
}
.stylePort{
    position: absolute;
    z-index: 99999 !important;
    width: 540px;
    display: block;
    height: 360px;
     box-shadow: 0px 0px 40px 1px rgba(0, 186, 253, 0.2);
    // box-shadow:0 0 20px 0px #00bafd, inset 0 0 0 0px #00bafd;
    background: #fff;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.pordtTitle{
    margin-top: 8px;
    // width: 100%;
    padding:0 10px;
    // position: fixed;
    z-index: 999;
    background: #fff;
    font-size: 14px;
    //   border-bottom:1px solid rgba(22, 119, 255, 1);
}
.portJsonSmallbox {
  height: 300px;
  overflow-y: scroll;
}
.titleborder {
  // border-bottom: none !important;
  // height: 48px;
}
.titleborder1{
   background: #00BAFD;
   color:#fff;
   padding: 3px 8px;
   text-align: center;
   border-radius: 35%;
}


.portJsonSmall{
    // border-left: 3px solid rgba(22, 119, 255, 1);
    height: 36px;
    // background: #F9F9F9;
    line-height: 36px;
    text-align: left;
    padding-left: 11px;
    font-size: 14px;
    font-weight: 500;
    margin: 15px 15px 0 15px;
    color:rgba(196, 196, 196, 1)
    // padding: 0 8px;
}
.smallJson{
   margin: 10px;
}
.smallJson li{
    float: left;
}
.smallJson li :hover{
    // background: rgba(22, 119, 255, 0.1);
    color:rgba(0, 186, 253, 1)
}
.smallJson li span{
 display: block;
    line-height: 24px;
    width: 172px;
    padding: 4px 6px;
    text-align: left;
    font-size: 12px;
    color: #325d97;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.selectSmall{
    background: #1677FF;
    color: #fff !important;
} 
.time {
  display: flex;
  align-items: center;
}
.el-form-item__content {
  // position: relative;
}
.post {
  position: relative;
  z-index: 99999;
}
.el-checkbox {
  // display: flex;
  // align-items: center;
}
::v-deep .el-dialog__wrapper {
  position: absolute !important;
}
#app {
  position: relative;
}
::v-deep .el-input-number .el-input__inner {
  text-align: left !important;
}
::v-deep .dialogMain .el-input__inner {
  height: 35px !important;
  line-height: 35px !important;
  // font-size: 12px !important;
}
::v-deep .dialogMain .el-form-item {
  margin-bottom: 5px;
}
::v-deep .dialogMain .el-checkbox__label{
  font-size: 12px;
  padding-left: 5px;
}
::v-deep .dialogMain .el-cascader__search-input{
  // font-size: 12px;
}
::v-deep .dialogMain .el-form-item__label{
  // font-size: 12px;
}
::v-deep .dialogMain .el-form-item__label{
color:#464646 !important;
}
.dialogHeader {
  font-size: 18px;
  margin: 15px 0;
  margin-left: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-icon-close {
  cursor: pointer;
}
::v-deep .el-tag.el-tag--info {
  // display: box;
  display: block !important;
  max-width: 218px !important;
  // white-space: pre-wrap !important
}
::v-depp .maicang .el-input__inner {
  width: 200px !important;
}
::v-depp .maicang .el-input {
  width: 200px !important;
}
::v-deep .el-select-dropdown {
  z-index: 101 !important;
}
::v-deep .el-select {
  display: block !important;
  position: static !important;
}
.dislog {
  z-index: 0 !important;
}
.seekDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-direction: column;
}
.seekDiv > div {
  line-height: 20px;
  // margin-bottom: 10px;
  font-size: 12px;
}
</style>
 