import store from '../store'
export default class myUtils {
  /**
   * 数字转大写
   * @param n String
   */
  static smalltoBIG(n) {
    var fraction = ['角', '分'];
    var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    var unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
    var head = n < 0 ? '欠' : '';
    n = Math.abs(n);
    var s = '';
    for (var i = 0; i < fraction.length; i++) {
      s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
    }
    s = s || '整';
    n = Math.floor(n);
    for (var i = 0; i < unit[0].length && n > 0; i++) {
      var p = '';
      for (var j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    }
    return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
  }

  static formatNum(f) {
    let m = Math.pow(10, 3);
    let result = parseInt(f * m, 10) / m;
    return Number(result.toFixed(2));
  }

  static notice(config) {
    this.$Notice[config.type || 'success']({
      title: config.title || "提示",
      desc: config.desc || '操作成功！'
    });
  }

  static formatDate(date, fmt) {
    date = new Date(date);
    if (!date || date.getTime() === 1537724510000 || date.getTime() === -28800000 || date.getTime() === -2209017600000 || date.getTime() === 631123200000) {
      return '';
    } else {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str,2));
        }
      }
      return fmt;
    }
  }

  static padLeftZero(val,minLength) {
    const MANY_ZEROS = "000000000000000000";
    if (typeof(val) != "string")
      val = String(val);
    return (MANY_ZEROS.substring(0, minLength - val.length)) + val;
  }
  /**
   * 基础数据项通过value获取name值
   * @param parentName 数据项父级名称 String
   * @param value Number
   * @returns name
   */
  static getBaseDataName(parentName, value) {
    let data = store.getters.baseData, resultData;
    resultData = data[parentName] ? data[parentName].filter(item => {
      return item.value === value;
    }) : [];
    return resultData.length > 0 ? resultData[0].name : '';
  }
  static getAuthStatus(code){
    let flag = true
    for (let i = 0; i < store.state.authList.length; i++) {
      if(code==store.state.authList[i].authCode){

        if(store.state.authList[i].haveCurrAuthFlag==1){
          flag=false;
        }
      }
    }
    return flag;
  }
}
