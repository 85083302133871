<template>
  <div>
    <Table class="table"
      ref="table"
      :highlight-row="true"
      :loading="loading"
      :height="tableHeight"
      @on-selection-change="checkItem"
      :columns="headerColumns"
      @on-expand="tableExpand"
      :data="tableData||List"></Table>
    <div id="page">
      <Page
        show-total
        :total="params.totalCount"
        :page-size="params.pageSize"
        :current="params.pageIndex"
        show-sizer
        :page-size-opts="[10,20,30,40,50,60,70,80,90,100,200,300,1000,10000]"
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
        placement="top">
      </Page>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "table-list",
    props: {
      //请求的参数
      params: {
        type: Object,
        default: {
          totalCount: 0,
          pageIndex: 1,
          pageSize: 10
        }
      },
      tableHeight: {
        type: Number,
      },
      //请求的的方法
      method: {
        type: String,
        default: '$post'
      },
      //请求的地址
      action: {
        type: String,
        required: true
      },
      //表头
      headerColumns: {
        type: Array,
        required: true
      },
      //数据支持，如果未传，将通过action获取数据
      tableData: {
        type: Array,
      },
    },
    data() {
      return {
        loading: false,
        tableSelectItem: [],
        List: [],
      }
    },
    computed: {
      
    },
    watch: {
      tableSelectItem: function (data) {
        this.$emit('on-selection-change', data);
      }
    },
    methods: {
      selectAll(type) {
        this.$refs['table'].selectAll(type);
        if (type === false) {
          this.tableSelectItem = [];
        }
      },
      pageChange(pageIndex) {
        this.params.pageIndex = pageIndex;
        this.getList();
      },
      tableExpand(row, status){
        this.$emit('on-expand',row, status);
      },
      pageSizeChange(pageSize) {
        this.params.pageSize = pageSize;
        this.getList();
      },
      getList() {
        this.loading = true;
        this[this.method](this.action, this.params).then(res => {
          this.loading = false;
          this.params.pageIndex = res.pageIndex?res.pageIndex:1;
          this.params.totalCount = res.totalCount?res.totalCount:10;
          this.params.pageSize = res.pageSize?res.pageSize:10;
          this.List = res.data.list || [];
          this.tableSelectItem = [];
          this.$emit('getMsg', res.msg);
          this.$emit('on-ok', this.List);
        });
      },
      getMsg(){
        return this.msg;
      },
      checkItem(selection) {
        this.tableSelectItem = selection;
      },
    }
  }
</script>

<style scoped>
  #page {
    padding: 10px;
    text-align: right;
    background-color: #fff;
    position: relative;
  }
</style>
