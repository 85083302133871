<template>
	<div id="app" :style="isMobile?'':'min-width: 1100px'">
        <div v-if="url == '/login'">
            <router-view  />
        </div>
        <div v-else>
                <keep-alive >
                    
                    <router-view  v-if="$route.meta.keepAlive"/>
                 </keep-alive>
                 <router-view v-if="!$route.meta.keepAlive"></router-view>

        </div>
	</div>
</template>

<script>
	import $ from 'jquery'
    import Md5 from 'js-md5';
	export default {
		name:'app',
		data(){
			return {
                isMobile: true, // 是否手机端打开
                url: '/',
				list:[
                   
                ],
                showUser: false,
                dialogPasswordVisible:false,
                formPassword:{
                    empId: 0,
                    fillName:localStorage.getItem("fillName"),
                    username:localStorage.getItem("username"),
                    positionName:localStorage.getItem("positionName"),
                    multistageDetp:localStorage.getItem("multistageDetp"),
                    password: "",
                    passwordSure:''
                },
                photoUrl:'https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3646262474,2245266927&fm=26&gp=0.jpg',
                showIcon:false,
                headers:{
                    token: localStorage.getItem("token"),
                    empId: localStorage.getItem("empId"),
                }
			}
        },
        mounted(){
            // this.getUrl();
            // this.photoUrl = 'https://test.dosbooking.com:8700/rbac/previewHeadImage?empId='+localStorage.getItem("empId");
            this.isMobile = this.is_mobile();
        },
		methods: {
            is_mobile() {
                var regex_match = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
                var u = navigator.userAgent;
                if (null == u) {
                    return true;
                }
                var result = regex_match.exec(u);
                
                if (null == result) {
                    return false
                } else {
                    return true
                }
            },
			shrink(el,index){
				el = $(el.currentTarget);
				let height = parseInt(el.css('height')) * el.next().children().length;
                if(this.list[index].list.length == 0){
                    if(this.$route.path !== this.list[index].path && this.list[index].path != ''){
                        this.$router.push(this.list[index].path)
                    }
                }else{
                    if(!this.list[index].shrink){
                        for(let i = 0; i < this.list.length; i++){
                            this.list[i].shrink = false;
                        }
                        this.list[index].shrink = true;
                        $('.list').stop().animate({height:0},500)
                        el.next().stop().animate({height:height},500)
                    }else{
                        this.list[index].shrink = false;
                        el.next().stop().animate({height:0},500)
                    }
                }
            },
            link(el,path){
                if(this.$route.path != path && path != ''){
                    this.$router.push(path);
                }
            },
            getUrl(){
                this.url = this.$route.path;
                for(let i = 0; i < this.list.length; i++){
                    if(this.list[i].list.length > 0){
                        for(let j = 0; j < this.list[i].list.length; j++){
                            if(this.list[i].list[j].path == this.url){
                                this.list[i].shrink = true;
                            }
                        }
                    }
                }
            },
            user(){
                this.showUser = !this.showUser;
            },
            exit(){
                this.$post('/login/empLoginOut', {}).then(res => {
                    if (res.code == 200) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("fillName");
                        localStorage.removeItem("username");
                        localStorage.removeItem("empId");
                        this.$router.push({
                            name: 'login'
                        })
                    }
                }).catch(err=>{
                })
            },
            updatePassword(){
                if(this.formPassword.password!=this.formPassword.passwordSure){
                    this.$Notice.info({
                        title: "请确认两次填写的密码是否一致~",
                    });
                    return;
                }
                this.$post('/login/changePasswordCurr', {empId:parseInt(localStorage.getItem("empId")),password:this.formPassword.password,passwordCode:Md5(this.formPassword.password)}).then(res => {
                    if (res.code == 200) {
                        this.exit();
                        this.$Notice.success({
                            title: "操作成功！"
                        });
                        this.dialogPasswordVisible = false;
                    }
                })
            },
           
           
            handleSuccess (res, file) {
                this.photoUrl = 'https://test.dosbooking.com:8700/rbac/previewHeadImage?empId='+localStorage.getItem("empId");
                this.showIcon = false;
            },
            handleFormatError (file) {
                this.$Notice.warning({
                    title: '格式错误',
                    desc: '文件 ' + file.name + ' 不是图片格式, 请上传正确的图片格式.'
                });
            },
            handleMaxSize (file) {
                this.$Notice.warning({
                    title: '超出文件限制',
                    desc: '文件  ' + file.name + ' 太大了, 不能超过 2M.'
                });
            },
            enterIcon(){this.showIcon=true},
            leaveIcon(){this.showIcon=false},
        },
        watch:{
            $route(to){
                // console.log(to)
                this.getUrl();
            }
        }
	}
</script>

<style lang="scss" scoped>
	// #app{
	// 	min-width: 1100px;
	// }

	.header{
        width: 100%;
        height: 60px;
        background: #1F2D45;
        .titel{
            color: #fff;
            font-size: 24px;
            line-height: 60px;
            margin-left: 22px;
            font-family:MicrosoftYaHeiLight;
            float: left;
        }
        .user{
            width: 28px;
            height: 28px;
            float: right;
            margin-right: 70px;
            background: url(~@/assets/images/user.png) no-repeat center/100%;
            margin-top: 16px;
            cursor: pointer;
            position: relative;
        }
    }

    .user-information{
        position: absolute;
        top: 43px;
        right: 50%;
        transform: translateX(50%);
        z-index: 2;
        width: 150px;
        padding: 16px 0;
        color: #fff;
        text-align: center;
        font-size: 12px;
        background: #1F2D45;
    }

    .api{
        padding: 10px 0;
    }

	.sidebar{
        width: 186px;
        height: calc(100vh - 60px);
        background: #283956;
        float: left;
        overflow: hidden;
    }

    .sidebar-warp{
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }

    .classification{
		display: block;
        width: 100%;
        height: 40px;
        color: #fff;
        font-size: 14px;
        font-family:MicrosoftYaHeiLight;
        cursor: pointer;
        &:hover{
            background: #0198E3;
			color: #fff;
        }
        .icon{
            width: 12px;
            height: 12px;
            float: left;
            margin-left: 30px;
            margin-right: 10px;
            margin-top: 14px;
        }
        .title{
            float: left;
            line-height: 40px;
        }
        .shrink{
            width: 12px;
            height: 12px;
            float: right;
            margin-right: 12px;
            margin-top: 17px;
        }
        img{
            width: 100%;
        }
    }

    .list{
        overflow: hidden;
        background: #202D44;
		height: 0;
		overflow: hidden;
        .item{
            display: block;
            height: 40px;
            line-height: 40px;
            padding-left: 56px;
            color: #fff;
            font-size: 13px;
            cursor: pointer;
            &:hover{
                background: #0198E3;
            }
        }
    }

    .open{
        height: auto;
    }

    .active{
        background: #0198E3;
    }
    .upload-icon{
        position: absolute;
        top:30%;
        left:20%;
        color:#3399ff;
        z-index: 2;
    }
    .show{
        display:block;
    }
    .hide{
        display:none;
    }
</style>
